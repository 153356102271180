import { Admin } from "../components/Pages";
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";

const AdminPage = () => {
  const [user, updateUser] = useState(null);
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => updateUser(user))
      .catch((err) => console.log(err));
  }, []);
  let isAdmin = false;
  if (user) {
    const {
      signInUserSession: {
        idToken: { payload },
      },
    } = user;
    console.log("payload: ", payload);
    if (
      payload["cognito:groups"] &&
      payload["cognito:groups"].includes("admin")
    ) {
      isAdmin = true;
    }
  }
  return isAdmin ? <Admin /> : <p>not admin</p>;
};
export default AdminPage;
